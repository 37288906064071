import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'

import Intro from 'elements/Intro'
import ImgOne from 'images/services-images/image-01.png'
import ImgTwo from 'images/services-images/image-02.png'
import ImgThree from 'images/services-images/image-03.jpeg'
import Accordion from 'elements/Accordion'

const ServicesContent = () => {
  const {
    rawData: { edges: services }
  } = useStaticQuery(query)

  const {
    servicesIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()

  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="Services" imgSrc={src} />
        <div className="flex mt-16 mb-16">
          <div className="w-24 h-24 mr-7">
            <img
              className="rounded-full w-full h-full object-cover border-vividBlue border-4"
              src={ImgOne}
              alt="Dr. Mato Njavro"
            />
          </div>
          <div className="w-24 h-24 mr-7">
            <img
              className="rounded-full w-full h-full object-cover border-vividBlue border-4"
              src={ImgTwo}
              alt="Dr. Zvonimir Stopić"
            />
          </div>
          <div className="w-24 h-24">
            <img
              className="rounded-full w-full h-full object-cover border-vividBlue border-4"
              src={ImgThree}
              alt="Dr. Ivica Bakota"
            />
          </div>
        </div>
        {services.length &&
          services.map(({ node: { id, title, content, list } }) => {
            return <Accordion title={title} content={content} list={list} key={`${id}-${title}`} />
          })}
      </div>
    </section>
  )
}

export default ServicesContent

const query = graphql`
  query useServices {
    rawData: allServicesJson {
      edges {
        node {
          id
          title
          content
          list
        }
      }
    }
  }
`
