import React from 'react'

import ServicesContent from 'components/Services'
import SEO from 'components/SEO'

const Services = () => {
  return (
    <>
      <SEO title="Services" />
      <ServicesContent />
    </>
  )
}

export default Services
